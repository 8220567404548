.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  line-height: 0.8em;
}

.App-logo {

}

.copytekst{
  margin-top:120px;
  font-size: 12px;
  margin-left: 24px;
  margin-right: 24px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width:100%;
  }
}

.App-header {
  background-color: #282c34;
  color: white;
}
.header-text{
  position: absolute;
  top:5%;
  width: 100%;
  font-size: 48px;
  font-weight: 100;
  color: white;
}
.header-tagline{
  display:block;
  padding-top: 24px;
  font-size: 16px;
  font-weight: 200; 
}

.App-link {
  color: #61dafb;
}